
import { useAccountStore } from "@/stores/account"

export default {
  middleware({ redirect }) {
    if (useAccountStore().token) {
      return redirect("/v2/projects")
    } else {
      return redirect("/v2/users/signin")
    }
  },
}
